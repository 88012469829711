import React from 'react';
import DocumentUpload from '../components/DocumentUpload';

const DocumentUploadPage = () => {
  return (
    <div>
      <h1>Document Upload</h1>
      <DocumentUpload />
    </div>
  );
};

export default DocumentUploadPage;